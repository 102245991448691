import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`The `}<em parentName="h2">{`lighthouse`}</em>{` concept`}</h2>
    <p>{`The restaurant sits atop a building on a hill that overlooks the Hapjeong area of Seoul. `}</p>
    <p>{`Owned by a couple that has lived for over 12 years in Korea and are well known and loved by all the community.`}</p>
    <h3>{`Request`}</h3>
    <p>{`The original design lacked personality.
I wanted to emphasize the importance of their place as a beacon that connects people. `}</p>
    <p>{`The location of the restaurant as a penthouse gave me the perfect idea to work with: a lighthouse. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d41b4a8674d1b93657def4e3a934b73b/af590/before-after_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACo0lEQVQoz12Sz0sUcRjG96/o0qVjhy79ARVBkYquTju7M/OdnZ3V3dEVf6QFHsxFJ0LUxZAOSQVdDCToEHlIog4FRYLQWiRIBK67a+bO5OzOru53fnyfWBWFDg8vPC/P5+WBN1Cn9Ead0hnqOOMV29bNPetQ1VpNp44zUad08oDSc3uWdd4ql6csy5owDUMvFop6sVDQjZKhW5Y1bpXL01a5fCWQz+cf5XI55PN5/FhfRzabRTa7ho2NjUNva2sL+ULhWrVW4yuVCuz9fZi2jcLuLja3t2FWq6jSOhq7im1PBlKJxHRnVEacyAfJKHEVQlxVFN04IY4qSVRTVQykUpcAtPqMwSnbtL5ZdJzCjksLO279V86hv3cPGADf8+4GEoqSUYkElUQcXoiy68MLjOvVWSzUwQSO8xVRQKqr6zKAtkaovLLmldQ7zBjSmZkYYaXRDDPffXZ9AMx10wEtpmYUScCQFHG7OIEFBx9j9NYEOgWZySHejwoR9GndJ8DK8kfPEPqZ+eAp/rbEYQaTbO/J4hGQ0nRAU2IZSeCR5kLuc1FmD3kOSzyP2ViSicF2n/Ah9HefAu1Xbz2jQ2PG4msYU/MwxQFmjc0eAes0fVhZDHGQ29ucZFhgwyTBehWNKaLCuOYmX7jJYaCn5xS4/MErTc8z48USK73/xErzC2xv7tkR0HEOgTMCxyHcEaRCe6sfCnJ+hON9vrXFCzY3uRLPo0/TTiu/fOOWooOeMTzhGyP3/VLnbf+vPkePK48FkrHYXDQSgRwOQw5HEG1Mnod87HXKMnoTiasAOhqh+h8D1S9fUVv9jtrqN1RXstj/uYnGMeZ59xpvczFOSFwlkhgnkhQn5EQxSZQ0VVUGU6kzAM66jhMDIAEg/0nyPU/1fP/CP7VHLyeYiOcQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/d41b4a8674d1b93657def4e3a934b73b/af590/before-after_2.png",
            "srcSet": ["/static/d41b4a8674d1b93657def4e3a934b73b/772e8/before-after_2.png 200w", "/static/d41b4a8674d1b93657def4e3a934b73b/e17e5/before-after_2.png 400w", "/static/d41b4a8674d1b93657def4e3a934b73b/af590/before-after_2.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Delivered`}</h3>
    <p>{`The new style was retro with references to a stamp. A new menu offering was also photographed and launched along the brand. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2273a1a32636833534b26725aa1c3549/bcec7/portfolio-casa_2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHu3OxqirAA/8QAGRABAQEAAwAAAAAAAAAAAAAAAQIAEBIg/9oACAEBAAEFAmgwjxUFYnqeP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAIDAAAAAAAAAAAAAAAAAAAQICFh/9oACAEBAAY/AnpUf//EABwQAAEEAwEAAAAAAAAAAAAAAAEAITFREBEggf/aAAgBAQABPyEjol6UIcPp9KyFCOP/2gAMAwEAAgADAAAAECzHPP/EABcRAAMBAAAAAAAAAAAAAAAAAAERIEH/2gAIAQMBAT8QKyP/xAAWEQADAAAAAAAAAAAAAAAAAAARICH/2gAIAQIBAT8QpT//xAAcEAEBAAIDAQEAAAAAAAAAAAABEQBBITGBECD/2gAIAQEAAT8QnHZQvL5hPEuzZ8e5CAK68wRliCu8qL3v8f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/2273a1a32636833534b26725aa1c3549/4b190/portfolio-casa_2.jpg",
            "srcSet": ["/static/2273a1a32636833534b26725aa1c3549/e07e9/portfolio-casa_2.jpg 200w", "/static/2273a1a32636833534b26725aa1c3549/066f9/portfolio-casa_2.jpg 400w", "/static/2273a1a32636833534b26725aa1c3549/4b190/portfolio-casa_2.jpg 800w", "/static/2273a1a32636833534b26725aa1c3549/bcec7/portfolio-casa_2.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/edcf8f96e8427e51132b81e504af1661/bcec7/portfolio-casa_3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIEA//EABcBAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAdUR053vSuNQ0B//xAAcEAACAgIDAAAAAAAAAAAAAAACAwEEABASExT/2gAIAQEAAQUCZaIG+tkmg5YLFQT+leVx4L3/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPwECY//EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAgEBPwFW4//EABwQAAICAgMAAAAAAAAAAAAAAAABAhESUSAhMf/aAAgBAQAGPwJxxVWY4xG3uiTlXp1Apb4f/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARISAxYXH/2gAIAQEAAT8hLFKlsKF5OwaQRYixU17BUbPuQ06LPD//2gAMAwEAAgADAAAAED8/PP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QAe3/xAAXEQEAAwAAAAAAAAAAAAAAAAABABAx/9oACAECAQE/EBQZf//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQVEQYcH/2gAIAQEAAT8Q1xMs11es5tpUimKT+FzU9wDEyBj0c6/cZIu1NH3veLoqkSS9ZDzIeHx//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/edcf8f96e8427e51132b81e504af1661/4b190/portfolio-casa_3.jpg",
            "srcSet": ["/static/edcf8f96e8427e51132b81e504af1661/e07e9/portfolio-casa_3.jpg 200w", "/static/edcf8f96e8427e51132b81e504af1661/066f9/portfolio-casa_3.jpg 400w", "/static/edcf8f96e8427e51132b81e504af1661/4b190/portfolio-casa_3.jpg 800w", "/static/edcf8f96e8427e51132b81e504af1661/bcec7/portfolio-casa_3.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/90878e7ffb2d549cbf8abf77ae278ebf/bcec7/portfolio-casa_4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAes3XFMm5QAf/8QAHBAAAQMFAAAAAAAAAAAAAAAAAQACAxESEyAi/9oACAEBAAEFAnSG7I5AqnR1/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8BY//EABYRAAMAAAAAAAAAAAAAAAAAAAERIP/aAAgBAgEBPwFGP//EABoQAAEFAQAAAAAAAAAAAAAAAAEAEBEgITH/2gAIAQEABj8CgBcfBX//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhIDFRgf/aAAgBAQABPyEKJ9mtbjJsfDIGsLh1w//aAAwDAQACAAMAAAAQDwAA/8QAFhEAAwAAAAAAAAAAAAAAAAAAESBR/9oACAEDAQE/EAif/8QAFhEAAwAAAAAAAAAAAAAAAAAAICEx/9oACAECAQE/EGUP/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBcSD/2gAIAQEAAT8QXlpdDweQwDjkGpYwD3UxIbG0HMMbvYchAYHnx//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/90878e7ffb2d549cbf8abf77ae278ebf/4b190/portfolio-casa_4.jpg",
            "srcSet": ["/static/90878e7ffb2d549cbf8abf77ae278ebf/e07e9/portfolio-casa_4.jpg 200w", "/static/90878e7ffb2d549cbf8abf77ae278ebf/066f9/portfolio-casa_4.jpg 400w", "/static/90878e7ffb2d549cbf8abf77ae278ebf/4b190/portfolio-casa_4.jpg 800w", "/static/90878e7ffb2d549cbf8abf77ae278ebf/bcec7/portfolio-casa_4.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/435be1f395fb26de202791419f1ff495/bcec7/portfolio-casa_5.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHqRsTi0ZWpYUB//8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIRAxAT/9oACAEBAAEFAm1NfYhMn9FK1iogEHf/xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAwEBPwGD/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPwHGF//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhExICL/2gAIAQEABj8ClVeSMktLaowxc//EAB0QAQEAAgEFAAAAAAAAAAAAAAERACFRIDFBYXH/2gAIAQEAAT8hEQ5L5wGDxdY+2LJg0ifMdN52EwIAHo6P/9oADAMBAAIAAwAAABDE4AD/xAAXEQEAAwAAAAAAAAAAAAAAAAABESAh/9oACAEDAQE/EFhhT//EABgRAQADAQAAAAAAAAAAAAAAAAEAECEx/9oACAECAQE/EOuwIbf/xAAcEAEBAAEFAQAAAAAAAAAAAAABEQAgIUFRYXH/2gAIAQEAAT8QJ9OS3ltMqtEpsZ78xLANR1gVPFVG7hI+URhAb0Q0f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/435be1f395fb26de202791419f1ff495/4b190/portfolio-casa_5.jpg",
            "srcSet": ["/static/435be1f395fb26de202791419f1ff495/e07e9/portfolio-casa_5.jpg 200w", "/static/435be1f395fb26de202791419f1ff495/066f9/portfolio-casa_5.jpg 400w", "/static/435be1f395fb26de202791419f1ff495/4b190/portfolio-casa_5.jpg 800w", "/static/435be1f395fb26de202791419f1ff495/bcec7/portfolio-casa_5.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e151f85ce04a197bf60dae53926f7412/bcec7/portfolio-casa_6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB8WnXldSg5t4MyAH/xAAbEAABBAMAAAAAAAAAAAAAAAABAAIDEBIhMv/aAAgBAQABBQIxuaKkmzZXROiv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAREgQf/aAAgBAwEBPwErI//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBL/2gAIAQIBAT8BZq//xAAZEAADAAMAAAAAAAAAAAAAAAABEBECEiH/2gAIAQEABj8CpYx1kfX/AP/EAB0QAAICAQUAAAAAAAAAAAAAAAABECExEVFxgaH/2gAIAQEAAT8h0yJLmaIbhmFSvAuA6P/aAAwDAQACAAMAAAAQeOfC/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREEH/2gAIAQMBAT8QWjGcz//EABkRAAMAAwAAAAAAAAAAAAAAAAABERAxQf/aAAgBAgEBPxCFoUKHcf/EAB0QAAIDAAIDAAAAAAAAAAAAAAERACExQVGBobH/2gAIAQEAAT8QymPEm8qXsGY4tRkQJoeJSABQAkVB46egHyDEGSBuV09z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/e151f85ce04a197bf60dae53926f7412/4b190/portfolio-casa_6.jpg",
            "srcSet": ["/static/e151f85ce04a197bf60dae53926f7412/e07e9/portfolio-casa_6.jpg 200w", "/static/e151f85ce04a197bf60dae53926f7412/066f9/portfolio-casa_6.jpg 400w", "/static/e151f85ce04a197bf60dae53926f7412/4b190/portfolio-casa_6.jpg 800w", "/static/e151f85ce04a197bf60dae53926f7412/bcec7/portfolio-casa_6.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      